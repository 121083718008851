// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "src/app/classes/environment/environment";
/** Environment state */
const envName: 'dev' | 'test' | 'prod' = 'prod';
/** URL of API gateway APIs */
const apiUrl: string = 'https://ob387gg0wf.execute-api.us-gov-west-1.amazonaws.com/prod';
/** AWS Region */
export const region: 'us-gov-west-1' | 'us-gov-east-1' = 'us-gov-west-1'
/** Police Agency City */
const pdCity: string = 'gilacso';
/** State Code of the agency */
const pdState:
  'al' | 'ak' | 'az' | 'ar' | 'ca' | 'co' | 'ct' | 'de' | 'dc' | 'fl' |
  'ga' | 'hi' | 'id' | 'il' | 'in' | 'ia' | 'ks' | 'ky' | 'la' | 'me' |
  'md' | 'ma' | 'mi' | 'mn' | 'ms' | 'mo' | 'mt' | 'ne' | 'mv' | 'nh' |
  'nj' | 'nm' | 'ny' | 'nc' | 'nd' | 'oh' | 'ok' | 'or' | 'pa' | 'ri' |
  'sc' | 'sd' | 'tn' | 'tx' | 'ut' | 'vt' | 'va' | 'wa' | 'wv' | 'wi' |
  'wy' = 'az'
/** Identity Pool Id */
const identityPoolId = 'us-gov-west-1:80e1f2f7-2265-4f26-a248-04bf1f47335d';
/** User Pool Id */
const userPoolId = 'us-gov-west-1_AQv4g7A18';
/** User Pool Client Id */
const userPoolClientId = '6hkavii9m16o54f8updojamhg4'

export const environment: Environment = {
  
  production: false,
  
  awsAmplifyConfig: {
    Auth: {
      Cognito: {
        identityPoolId: identityPoolId,
        userPoolId: userPoolId,
        userPoolClientId: userPoolClientId
      }
    },
    Storage: {
      S3: {
        bucket: `${pdState}-${pdCity}-${envName}-ewarrant-uploads`,
        region: region,
      },
    },
    API: {
      REST: {
        cwApi: {
          endpoint: apiUrl,
          region: region
        }
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
